"use client";

import { ArrowRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { useIntl } from "react-intl";

export const NotFoundPage = () => {
  const intl = useIntl();
  const errorCode = 404;

  return (
    <main className="mx-auto flex w-full max-w-7xl flex-auto flex-col justify-center px-6 py-24 sm:py-64 lg:px-8">
      <p className="text-base font-semibold leading-8 text-primary-11">{errorCode}</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-primary-12 sm:text-5xl">
        {intl.formatMessage({
          defaultMessage: "Page not found",
          id: "QRccCM",
        })}
      </h1>
      <p className="mt-6 text-base leading-7 text-neutral-11">
        {intl.formatMessage({
          defaultMessage: "Sorry, we couldn’t find the page you’re looking for.",
          id: "S8ZquU",
        })}
      </p>
      <div className="mt-10">
        <Link
          className="inline-flex items-center rounded-sm text-sm font-semibold leading-7 text-primary-11"
          href="/"
        >
          <ArrowRightIcon aria-hidden className="me-1 size-4 ltr:-scale-x-100" />
          {intl.formatMessage({
            defaultMessage: "Back to home",
            id: "aIRWQB",
          })}
        </Link>
      </div>
    </main>
  );
};

export default NotFoundPage;
